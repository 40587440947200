import store from "@/store";
import router from "@/router";

const TabPageScroll = {
  data() {
    return {
      scrollMap: new Map(),
      domWeakMap: new WeakMap(),
    };
  },
  methods: {
    add(selector) {
      if (this.scrollMap.has(selector)) {
        const dom = this.scrollMap.get(selector);
        dom.scrollTo(0, this.domWeakMap.get(dom) || 0);
        return;
      }

      const dom = document.querySelector(selector);
      if (!dom) return;
      this.scrollMap.set(selector, dom);
      dom.addEventListener("scroll", this.scrollHanlder, true);
    },
    del(selector) {
      if (!this.scrollMap.has(selector)) return;
      const { dom } = this.scrollMap.get(selector);

      dom.removeEventListener("scroll", this.scrollHanlder, true);

      this.scrollMap.delete(selector);
    },
    onChange(name) {
      setTimeout(() => {
        this.add(`#${name}`);
      }, 100);
    },
    scrollHanlder(e) {
      const dom = e.currentTarget;
      this.domWeakMap.set(dom, e.target.scrollTop);
      store.commit('app/SET_SCROLL', {
        key: this.$route.path,
        scrollObj: dom.id,
        scrollDistance: e.target.scrollTop,
      });
    },
  },
  activated() {
    for (const dom of this.scrollMap.values()) {
      dom.addEventListener("scroll", this.scrollHanlder, true);
      dom.scrollTo(0, this.domWeakMap.get(dom) || 0);
    }
  },
  deactivated() {
    for (const dom of this.scrollMap.values()) {
      dom.removeEventListener("scroll", this.scrollHanlder, true);
    }
  },
};
export default TabPageScroll;
