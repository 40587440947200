<template>
 <div class="AppList">
  <div class="content">
<!--   <div class="img-container" v-if="this.AppAdvArr.length">-->
<!--    <van-swipe class="swiper" :autoplay="3000" indicator-color="#FECD55">-->
<!--     <van-swipe-item-->
<!--       v-for="(item, index) in AppAdvArr"-->
<!--       :key="index"-->
<!--       @click="toJump(item, 1)"-->
<!--     >-->
<!--      <ImgDecypt :src="item.image" :key="index"/>-->
<!--     </van-swipe-item>-->
<!--    </van-swipe>-->
<!--    <div-->
<!--      v-for="(item, index) in AppAdvArr"-->
<!--      @click="toJump(item, 1)"-->
<!--      :key="index"-->
<!--    >-->
<!--      <ImgDecypt :key="index" class="avatar" :src="item.image" />-->
<!--    </div>-->
<!--   </div>-->
   <div class="contentBox">
    <div class="recommend" v-if="this.shuHengApp.length">
     <div class="title">
      <!--     <div class="line"></div>-->
      <span style="color: rgba(41, 46, 48, 1); font-size: 16px">官方推荐</span>
     </div>
     <div class="appList">
      <div
        class="appItem"
        v-for="(item, index) in shuHengApp.slice(0, 10)"
        :key="index"
        @click="toJump(item, 2)"
      >
       <div class="app-mess">
        <ImgDecypt
          :key="item.icon"
          class="app-avatar"
          :src="item.icon"
        />
        <div class="mess">
         <p>{{ item.name }}</p>
        </div>
       </div>
      </div>
     </div>
    </div>
    <div class="list">
     <div class="title">
      <!--    <div class="line"></div>-->
      <span style="color: #333333; font-size: 16px">热门应用</span>
     </div>
     <div
       class="list-item"
       v-for="(item, index) in shuAppList"
       :key="index"
     >
      <div class="app-mess">
       <ImgDecypt
         :key="item.icon"
         class="app-avatar"
         :src="item.icon"
       />
       <div class="mess">
        <p>{{ item.name }}</p>
       </div>
      </div>
      <div class="btn flex-center-center" @click="toJump(item, 2)"></div>
     </div>
    </div>
   </div>
   </div>
  <!-- <Loading v-else /> -->
 </div>
</template>

<script>
import { getRecreatio, upperRecreatio } from "@/api/movies";
import Loading from "@/components/Loading";
import ImgDecypt from "@/components/ImgDecypt";
import { getDevType } from "@/utils";
import { AdType, getAdItem, jumpAdv } from "@/utils/getConfig";

export default {
 name: "AppList",
 components: {
  // Loading,
  ImgDecypt,
 },
 data() {
  return {
   // url: null
   loading: true,
   appAdvList: [],
   gameAdvList: [],
   shuHengApp: [],
   shuAppList: [],
   AppAdvArr: [],
  };
 },
 mounted() {
  this.getList();
 },
 methods: {
  async toJump(data, types) {
   try {
    window.open(data.url);
    let req = {
     id: data.id, //点击id
     type: types == 1 ? "adv" : data.type, //广告类型. app 或 adv
     sysType: getDevType(), // 设备类型. android或ios
    };

    let res = await this.$Api(upperRecreatio, req);
   } catch (e) {
    console.log(e);
   }
  },
  async getList() {
   try {
    let res = await this.$Api(getRecreatio, {});
    const { data } = res;
    console.log(res);
    this.shuAppList = data.shuApp || [];
    this.appAdvList = data.adv || [];
    this.shuHengApp = data.hengApp || [];
    this.filterList();
    this.loading = false;
   } catch (error) {
    console.log(error);
   }
  },
  filterList() {
   for (let i = 0; i < this.appAdvList.length; i++) {
    const element = this.appAdvList[i];
    if (element.moduleType == 1) {
     element.cover = element.image;
     this.AppAdvArr.push(element);
    }
   }
  },
 },
};
</script>

<style lang="scss" scoped>
.AppList {
 width: 100%;
 height: 100%;
 background-color: rgba(245, 245, 245, 1);

 .content {
  height: calc(100vh - 100px);
  overflow-y: auto;
  padding-bottom: 200px !important;

  .contentBox {
   padding: 0 10px;
   .recommend {
    width: 100%;
    height: auto;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    padding-bottom: 8px;
   }
  }
 }
 .content::after {
  content: "";
  display: block;
  width: 100%;
  height: 200px;
 }

 .swiper {
  overflow: hidden;
  width: auto;
  //width: 100%;
  height: 143px;
  margin: 4px auto;
  margin-bottom: 12px;
  box-sizing: border-box;
  /deep/ .van-swipe-item {
   width: 343px !important;
   height: 143px !important;
  }
  /deep/ .van-swipe__indicators {
   background: none !important;
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
  }
  /deep/ .van-swipe__indicator {
   width: 8px !important;
   height: 8px !important;
   background-color: rgba(148,214,248, 0.45) !important;
   opacity: 0.4 !important;
  }
  /deep/ .van-swipe__indicator--active {
   width: 10px !important;
   height: 10px !important;
   background-color: #94D6DA !important;
   opacity: 1 !important;
  }
  /deep/ .van-image__img {
   border-radius: 8px;
   object-fit: fill !important;
  }
 }

 .title {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: rgb(51, 51, 51);
  font-weight: bold;
  padding: 18px 0 0 12px;

  .line {
   width: 4px;
   height: 22px;
   background: #94d6da;
   border-radius: 14.5px;
   margin-right: 12px;
  }
 }
 .appList {
  padding: 0 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 12px;
  margin-top: 12px;
  margin-bottom: 14px;

  .appItem {
   border-radius: 8px;
   /deep/ .van-image__img {
    border-radius: 8px;
   }
   .mess {
    text-align: center;
    font-size: 12px;
    color: rgba(85, 85, 85, 1);
    margin-top: 6px;
   }
  }
 }

 .list {
  margin-top: 18px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;

  .list-item {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 14px 15px 14px;
   margin-top: 15px;

   .app-mess {
    display: flex;
    align-items: center;

    .app-avatar {
     width: 60px;
     height: 60px;
     border-radius: 8px;
     margin-right: 10px;
     /deep/ .van-image__img {
      border-radius: 8px;
     }
    }

    .mess {
     font-size: 12px;
     color: #333333;
    }
   }

   .btn {
    width: 65px;
    height: 28px;
    background-image: url("~@/assets/png/appDownloadBtn.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    //line-height: 28px;
    //border-radius: 18px;
    //font-size: 12px;
    //color: rgb(255, 255, 255);
   }
  }
 }
}
</style>
