<template>
 <div class="followTab">
  <div class="content oepnX">
   <van-tabs
     class="followTabs oepnX"
     type="card"
     swipeable
     animated
     line-width="24"
     title-active-color="#333333"
     title-inactive-color="rgba(153, 153, 153, 1)"
     line-height="6"
     v-model="activeName"
     v-if="navList && navList.length"
     @change="clickTag"
     sticky
   >
    <van-tab :title="item.label" v-for="(item, index) in navList" :key="index">
     <FollowUser v-if="item.value === 0" />
     <FollowTag v-else />
    </van-tab>
   </van-tabs>
  </div>
 </div>
</template>

<script>
import {AdType, getAdItem, jumpAdv, setSearchKey} from "@/utils/getConfig";
import {querySearchList, queryTopicMediaList} from "@/api/video";
import { moduleListMore } from "@/api/home";
import Loading from "@/components/Loading";
import PullRefresh from "@/components/PullRefresh";
import FollowUser from "@/views/community/followTab/followUser.vue";
import FollowTag from "@/views/community/followTab/followTag.vue";
import store from "@/store";
export default {
 name: "followTab",
 components: {
  FollowUser,
  FollowTag
 },
 data() {
  return {
   activeName: 0,
   pageNumber: 1,
   pageSize: 12,
   isLoading: true,
   loading: true,
   refreshing: false,
   finished: false,
   isNoData: false,
   error: false,
   navList: [
    {
     label: 'UP主',
     value: 0,
    },
    {
     label: '话题',
     value: 1,
    },
   ],
  };
 },
 created() {
 },
 mounted() {
 },
 methods: {
  clickTag(index) {
   if (index != null) {
    this.activeName = index;
   }
   store.commit('app/SET_SCROLL_INFO', {
    key: this.$route.path,
    index: index,
   });
  },
 },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/main.scss";
.followTab {
 height: 100%;
 .content {
  //height: calc(100vh - 48px - 44px);
  //overflow-y: auto;
  //background: #f5f5f5;
  /deep/ .postItem {
   //padding: 0;
   //padding-left: 10px;
  }
  /deep/ .communityList:nth-child(1) {
   .communityHead {
    margin-top: 0;
   }
  }
 }
 .followTabs {
  padding-top: 18px;
  @include cardTab();
  /deep/ .van-tabs__wrap {
   padding: 0 12px;
   background: #f5f5f5 !important;
  }
  /deep/ .van-sticky--fixed{
   background: #f5f5f5;
   top: 43px;
   height: 50px;
   padding: 0 10px;
   padding-top: 18px;
  }
 }
}
</style>
