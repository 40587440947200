<template>
    <div class="communityList">
        <div class="communityItem" v-for="(item,index) in communityList" :key="index">
            <PostItem :isShowHead="true" :postInfo="item" :key="item.id" @focusOn="focusOn" />
            <!-- <div class="communityContent">
                <div class="communityHead">
                    <div class="userContent">
                        <div class="portrait" @click="jumpUserHome(item.publisher)">
                            <ImgDecypt class="avatarImg" :src="item.publisher.portrait" round />
                        </div>
                        <div class="userInfo" @click="details(item)">
                            <div class="userName">
                                <span>{{ item.publisher.name }}</span>
                                <span class="vip">
                                    <img v-if="item.publisher.vipLevel == 1" class="vipIco" src="@/assets/png/userVip.png">
                                </span>
                                <span class="topCommunity" v-if="item.isTopping">置顶</span>
                                <span class="merchant" v-if="item.publisher.officialCertExpire && new Date(item.publisher.officialCertExpire).getTime() > new Date().getTime()">认证商家</span>
                                <span class="blogger" v-if="item.publisher.superUserExpire && new Date(item.publisher.superUserExpire).getTime() > new Date().getTime()">认证博主</span>
                                <span class="supreme" v-if="item.publisher.videoFreeExpiration && new Date(item.publisher.videoFreeExpiration).getTime() > new Date().getTime()">至尊会员</span>
                            </div>
                            <div class="releaseTime">
                                <span>{{item.reviewAt | time}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="focusBtn" v-if="!item.publisher.hasFollowed" @click="focus(item.publisher)">
                        <span>关注</span>
                    </div>
                    <div class="focusBtn alreadyFocus" v-else @click="focus(item.publisher)">
                        <span>已关注</span>
                    </div>
                </div>
                <div class="communityText" @click="details(item)">
                    <span>{{item.title}}</span>
                </div>
                <div v-if="item.sourceURL" class="videoContent" @click="playVideo(item)">
                    <div class="playTime">
                        {{item.playTime | videotime}}
                    </div>
                    <img class="playBtn" src="@/assets/png/playBtn.png" />
                    <ImgDecypt class="videoImg" :src="item.cover" />
                </div>
                <div class="imgContent" v-else>
                    <div class="imgItem" v-for="(itemCover,indexCover) in item.seriesCover.slice(0,9)" :key="indexCover" @click="previewImg(item.seriesCover,indexCover)">
                        <ImgDecypt :src="itemCover" />
                    </div>
                </div>
                <div class="tags">
                    <div class="tag" v-for="(itemTag,indexTag) in item.tags" :key="indexTag" @click="tagJump(itemTag)">
                        <span># {{itemTag.name}}</span>
                    </div>
                </div>

                <div class="bottomBar">
                    <div class="pageViews">
                        <p>
                            <img src="@/assets/png/communityPreview.png">
                        </p>
                        <p>{{item.playCount | watchCount}}</p>
                    </div>
                    <div class="bottomItem" @click="giveLike(item)">
                        <p>
                            <img v-if="item.vidStatus.hasLiked" src="@/assets/png/communityLikeActive.png">
                            <img v-else src="@/assets/png/communityLike.png">
                        </p>
                        <p>{{item.likeCount | watchCount}}</p>
                    </div>
                    <div class="bottomItem" @click="rewardGolden(item)">
                        <p>
                            <img src="@/assets/png/communityBean.png">
                        </p>
                        <p>{{parseInt(item.rewarded) | watchCount}}</p>
                    </div>
                    <div class="bottomItem" @click="openComment(item)">
                        <p>
                            <img src="@/assets/png/communityComments.png">
                        </p>
                        <p>{{item.commentCount | watchCount}}</p>
                    </div>

                </div>
            </div> -->



            <!-- <div class="advertising" v-if="advList.length > 0 && ((index + 1)  % 4 == 0)" @click="goAdv(advList[(index + 1) % advList.length])">
                <ImgDecypt class="advImg" :src="advList[(index + 1) % advList.length].cover" />
            </div> -->

<!--            <div class="advertising">-->
<!--                <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">-->
<!--                    <van-swipe-item v-for="(item,index) in advList" :key="index" @click="goAdv(item)">-->
<!--                        <ImgDecypt class="advImg" :src="item.cover" />-->
<!--                    </van-swipe-item>-->
<!--                </van-swipe>-->
<!--            </div>-->


        </div>
    </div>
</template>

<script>
import AdvSwiper from "@/components/AdvSwiper";
import PostItem from "@/components/PostItem";
// import ImgDecypt from "@/components/ImgDecypt";
import {setSessionItem} from "@/utils/longStorage";
import {cancleZan, careOrcancle, zan} from "@/api/user";
import {Toast} from "vant";
import {mapGetters} from "vuex";
import {AdType, getAdItem, jumpAdv} from "@/utils/getConfig";

export default {
  name: "CommunityList",
    components:{
        PostItem,
        // ImgDecypt,
    },
    props:{
        communityList:{
            type: Array,
            default: () => {
                return [];
            }
        }
    },data(){
      return {
          advList:[]
      }
    },computed:{
        ...mapGetters({
            videoApi: 'videoApi',
            userInfo: 'userInfo',
            isVip: 'isVip'
        }),
    },mounted() {
        this.advList = getAdItem(AdType.COMMUNITY_ADV);
    },methods:{
        playVideo(item){
            /**
             * 跳转短视频播放
             */
            setSessionItem("currentWorks", JSON.stringify(item));
            this.$router.push({
                path:"/shortVideo"
            })
        },jumpUserHome(item){
            /**
             * 她的主页
             */
            this.$router.push({
                path:"/userHomePage",
                query:{
                    uid: item.uid
                }
            })
        },previewImg(list, index){

            if (this.userInfo.isVip && this.userInfo.vipLevel) {
                /**
                 * 图片预览
                 */
                this.$router.push({
                    name: "PicturePreview",
                    params: {
                        imgs: list,
                        index: index
                    },
                });
            }else {
                Toast({
                    message: "只有会员才能预览哦",
                    duration: 1500,
                })
            }

        },async focus(item) {
            /**
             * 关注/取消关注
             */
            let req = {
                followUID: item.uid,
                isFollow: !item.hasFollowed,
            }
            let res = await this.$Api(careOrcancle, req);

            if(res && res.code == 200){
                if(!item.hasFollowed){
                    item.hasFollowed = true;
                    this.$toast({
                        message: '关注成功',
                        position: 'top',
                    });
                }else{
                    item.hasFollowed = false;
                    this.$toast({
                        message: '取消关注',
                        position: 'top',
                    });
                }
            }
        },async giveLike(item) {
            let req = {
                objID: item.id,
                type: item.sourceURL?'video':'image',
            }

            if (item.vidStatus.hasLiked) {
                /**
                 * 取消点赞
                 */
                let res = await this.$Api(cancleZan, req);
                if(res && res.code == 200){
                    item.vidStatus.hasLiked = false;
                    this.$toast({
                        message: '取消点赞',
                        position: 'top',
                    });
                }

            } else {
                /**
                 * 点赞
                 */
                let res = await this.$Api(zan, req);
                if(res && res.code == 200){
                    item.vidStatus.hasLiked = true;
                    this.$toast({
                        message: '点赞成功',
                        position: 'top',
                    });
                }
            }
        },details(item){
            /**
             * 帖子详情
             */
            if(item.sourceURL){
                this.playVideo(item);
            }else{
                this.$router.push({
                    path:"/postDetails",
                    query:{
                        videoID: item.id
                    }
                })
            }
        },rewardGolden(item){
            //打赏
            this.$store.commit("user/SET_REWAE", {show: true, info: item})
        },openComment(item){

            //评论
            this.$store.commit("user/SET_COMMENTPOP", {show: true, info: item})

            // if (this.userInfo.isVip && this.userInfo.vipLevel) {
            //     //评论
            //     this.$store.commit("user/SET_COMMENTPOP", {show: true, info: item})
            // } else {
            //     Toast({
            //         message: "只有会员才能评论哦",
            //         duration: 1500,
            //     })
            // }

        },tagJump(item){
            /**
             * 话题详情
             */
            this.$router.push({
                path:"/topicDetails",
                query:{
                    id:item.id
                }
            })
        }, goAdv(item) {
            jumpAdv(item);
        },
        focusOn(data) {
            this.communityList.forEach(i => {
                if (i.publisher.uid == data.uid) {
                    i.publisher.hasFollowed = data.focusSta;
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .communityList{
        .communityItem{
         background: #fff;
            .communityContent{
                padding: 18px 0 12px 0;

                //border-top: solid rgb(43,39,74) 1px;
                //border-bottom:  solid rgb(230,230,230) 1px;
            }

            .advertising{
                display: flex;
                justify-content: center;
                padding: 12px 0 12px 0;
                // border-bottom:  solid rgb(230,230,230) 1px;
                /deep/ .vanImage {
                  border-radius: 8px;
                }
                /deep/ .van-image__img {
                  border-radius: 8px;
                }
                .advImg{
                    width: 351px;
                    height: 73.13px;
                    // border-radius: 6px;
                    margin: 0 auto;

                    // /deep/ .van-image{
                    //     border-radius: 6px;
                    // }

                    // /deep/ .van-image__img{
                    //     border-radius: 6px;
                    // }
                }
            }

            .communityHead{
                padding: 0 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .portrait{
                    width: 44px;
                    height: 44px;
                    background: rgb(255,255,255);
                    border-radius: 50%;
                }

                .userContent{
                    display: flex;
                    align-items: center;

                    .userInfo{
                        margin-left: 8px;

                        .userName{
                            font-size: 15px;
                            color: rgb(0,0,0);
                            display: flex;
                            align-items: center;

                            .vip{

                                .vipIco{
                                    width: 24px;
                                    height: 12px;
                                    margin-left: 9px;
                                }
                            }

                            .topCommunity{
                                color: rgb(0,0,0);
                                font-size: 8px;
                                background: rgb(215,65,85);
                                border-radius: 15px;
                                padding: 2px 8px;
                                margin-left: 9px;
                            }

                            .merchant{
                                color: rgb(0,0,0);
                                font-size: 8px;
                                background: rgb(255,0,216);
                                border-radius: 15px;
                                padding: 2px 8px;
                                margin-left: 9px;
                            }

                            .blogger{
                                color: rgb(0,0,0);
                                font-size: 8px;
                                background: rgb(255,139,39);
                                border-radius: 9px;
                                padding: 2px 8px;
                                margin-left: 9px;
                            }

                            .supreme{
                                color: rgb(0,0,0);
                                font-size: 8px;
                                background: rgb(255,69,0);
                                border-radius: 15px;
                                padding: 2px 8px;
                                margin-left: 9px;
                            }
                        }

                        .releaseTime{
                            font-size: 12px;
                            color: rgb(153,153,153);
                            margin-top: 6px;
                        }
                    }
                }

                .focusBtn{
                    font-size: 12px;
                    background: rgb(153,153,153);
                    border-radius: 12.5px;
                    padding: 4px 13px;
                    color: rgb(255,255,255);

                    display: flex;
                    justify-items: center;
                    align-items: center;
                }

                .alreadyFocus{
                    font-size: 12px;
                    background: rgb(113,69,231);
                    border-radius: 12.5px;
                    padding: 4px 7px;
                    color: rgb(255,255,255);
                }

            }

            .communityText{
                overflow: hidden;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                margin-top: 17px;
                padding: 0 12px;
                color: #000000;
            }

            .fullBtn{
                font-size: 15px;
                color: rgb(55,231,210);
                margin-top: 6px;
            }

            .videoContent{
                margin-top: 3px;
                margin-left: 12px;

                .playBtn{
                    width: 18px;
                    height: 21px;
                    position: absolute;
                    z-index: 2;
                    margin-top: 115px;
                    margin-left: 80px;
                    transform:translate(-50%,-50%);
                }

                .playTime{
                    position: absolute;
                    z-index: 2;
                    margin-top: 205px;
                    margin-left: 115px;
                }

                .videoImg{
                    width: 160px;
                    height: 230px;

                    /deep/ .van-image{
                        border-radius: 6px;
                    }

                    /deep/ .van-image__img{
                        border-radius: 6px;
                    }
                }
            }

            .imgContent{
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin-top: 3px;
                padding-left: 12px;

                .imgItem{
                    width: 111px;
                    height: 111px;
                    background: rgb(230,230,230);
                    border-radius: 6px;
                    margin-top: 9px;
                    margin-right: 9px;

                    /deep/ .van-image{
                        border-radius: 6px;
                    }

                    /deep/ .van-image__img{
                        border-radius: 6px;
                    }
                }


            }

            .tags{
                display: flex;
                margin-top: 14px;
                padding: 0 12px;
                .tag{
                    font-size: 12px;
                    color: rgb(113,110,137);
                    margin-right: 18px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .bottomBar{
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: center;
                //width: 100%;
                margin-top: 14px;
                font-size: 14px;
                color: rgb(164,161,189);
                padding: 0 24px;

                .pageViews{
                    img{
                        width: 23px;
                        height: 20px;
                    }
                }

                .bottomItem{
                    img{
                        width: 25px;
                        height: 20px;
                    }
                }



                .likeComments{
                    display: flex;
                    align-items: center;

                    .like{
                        display: flex;
                        align-items: center;

                        img{
                            width: 19px;
                            height: 17px;
                            margin-right: 3px;
                        }
                    }

                    .comments{
                        display: flex;
                        align-items: center;
                        margin-left: 14px;

                        img{
                            width: 19px;
                            height: 16px;
                            margin-right: 3px;
                        }
                    }
                }
            }
        }
    }
</style>
