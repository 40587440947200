<template>
 <div class="followUser my-refresh">
   <PullRefresh
     v-if="!isLoading"
     :loading="loading"
     :refreshing="refreshing"
     :finished="finished"
     @onLoad="onLoad"
     @onRefresh="onRefresh"
     :isNoData="isNoData"
     :error="error"
     :is-higeht-max="true"
     :hasAdvPagination="true"
     className="Page"
   >
    <CommunityList :communityList="gCommunityList"></CommunityList>

   </PullRefresh>
   <Loading v-else />
  </div>
</template>

<script>
import {AdType, getAdItem, jumpAdv, setSearchKey} from "@/utils/getConfig";
import {querySearchList, queryTopicMediaList} from "@/api/video";
import { moduleListMore } from "@/api/home";
import Loading from "@/components/Loading";
import PullRefresh from "@/components/PullRefresh";
import CommunityList from "@/components/community/CommunityList.vue";
import {queryFollowDynamicsList} from "@/api/community";
export default {
 name: "followUser",
 components: {
  CommunityList,
  Loading,
  PullRefresh,
 },
 data() {
  return {
   pageNumber: 1,
   pageSize: 12,
   gCommunityList: [],
   isLoading: true,
   loading: true,
   refreshing: false,
   finished: false,
   isNoData: false,
   error: false,
  };
 },
 created() {
 },
 mounted() {
  this.getFollowDynamicsList("refresh");
 },
 methods: {
  async getFollowDynamicsList(type) {
   /**
    * 帖子关注列表
    */
   let req = {
    pageNumber: this.pageNumber,
    pageSize: this.pageSize,
   }
   let res = await this.$Api(queryFollowDynamicsList, req);

   this.loading = false;
   this.refreshing = false;
   this.isLoading = false;

   if (res && res.code == 200) {
    console.log(res.data.list);

    if (res.data.list) {
     if (type == "refresh") {
      this.gCommunityList = res.data.list;
     } else {
      this.gCommunityList = this.gCommunityList.concat(res.data.list);
     }
    } else {
     this.gCommunityList = [];
    }

    if (this.gPageNumber === 1 && this.gCommunityList.length === 0) {
      this.gIsNoData = true;
    }

    if (res.data.list) {
     if (!res.data.hasNext || res.data.list.length < req.gPageSize) {
      this.finished = true;
     }
    } else {
     this.finished = true;
    }

   }

  },
  onRefresh() {
   this.pageNumber = 1;
   this.refreshing = true;
   this.finished = false;
   this.isNoData = false;
   this.loading = true;
   this.getFollowDynamicsList("refresh");
  },
  onLoad() {
   this.loading = true;
   if (this.error) {
    this.error = false;
   } else {
    this.pageNumber++;
   }
   this.getFollowDynamicsList();
  },
 },
};
</script>

<style lang="scss" scoped>
.followUser {
 height: 100%;
}
</style>
